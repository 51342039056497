import React from 'react';
import PropTypes from "prop-types";

import Column from "src/components/Column";
import Card from "src/components/Card";
import VehicleDescription from "src/components/VehicleDescription";

import css from './index.module.scss';

const VehicleDetails = ({description, details}) => {
  return (
    <Card className={css.vehicleDetailsRoot}>
      {details && details.length ?
        <Column direction="cc">
          <h3>Details</h3>
          <Column className={css.vehicleDetails}>
            {details.map((detail, i) => <Detail label={detail.label} detail={detail.value} key={i}/>)}
          </Column>
        </Column>
        : ""
      }
      <VehicleDescription text={description.text} features={description.features}/>
    </Card>
  );
};

const Detail = ({label, detail}) => {
  if(detail) {
    return (
      <Column className={css.detailsItem}>
        <p>{label}: </p>
        <p>{detail}</p>
      </Column>
    );
  }
  return null;
};

VehicleDetails.propTypes = {
  description: PropTypes.object,
  details: PropTypes.arrayOf(PropTypes.object)
};

export default VehicleDetails;