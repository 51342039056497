import React from 'react';
import PropTypes from "prop-types";

import Column from "src/components/Column";

import css from './index.module.scss';

const VehicleDetails = ({features, text}) => {
  
  return (
    <Column className={css.vehicleDescriptionRoot} direction="cc">
      <h3>Description</h3>
      <Column className={css.vehicleDescription}>
        {text && text.length ? <p>{text}</p> : ""}
        {features && features.length ?
          <React.Fragment>
            <p className={css.features}>Features: </p>
            <ul>
              {features.map((feature, i) => <li key={i}>{feature}</li>)}
            </ul>
          </React.Fragment>
          : ""
        }
      </Column>
    </Column>
  );
};

VehicleDetails.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string
};

export default VehicleDetails;