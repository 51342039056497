import React from "react";
import { graphql } from "gatsby";
import Carousel from 'nuka-carousel';
import Img from 'gatsby-image'

import Column from "src/components/Column";
import VehicleDetails from "src/components/VehicleDetails";

import css from "./index.module.scss";
import Header from "../../components/Header";


const Advert = props => {
  const { description, details, images, price, title } = props.data.salesRentalsJson;
  const isForSale = props.data.salesRentalsJson.featureImage.relativeDirectory.includes("for-sale");
  
  return (
    <main>
      <Column className={css.advertRoot} direction="cc">
        <Header primaryText={title} secondaryText={isForSale ? `For Sale - €${price}` : "To Hire"}/>
        <Carousel className={css.carousel} wrapAround>
          {images.map(image => <Img key={image.childImageSharp.fluid.src} fluid={image.childImageSharp.fluid}/>)}
        </Carousel>
        <VehicleDetails details={details} description={description}/>
      </Column>
    </main>
  )
};

export const vehicleQuery = graphql`
  query vehicleQuery($id: String!) {
    salesRentalsJson(id: { eq: $id }) {
      engineSize
      featureImage {
        relativeDirectory
        childImageSharp {
          fluid (maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fuelType
      id
      images {
        childImageSharp {
          fluid (maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mileage
      price
      title
      year
      description {
        features
        text
      }
      details {
        label
        value
      }
    }
  }
`;

export default Advert;